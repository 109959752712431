import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  clearAllAction,
  userAuthChange,
  userAuthError,
  userAuthPending,
} from "../redux/slices/user";
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { FetchedComponent } from "./fetchers/FetchedComponent";
import { State } from "../redux/rootReducer";
import { makeStyles } from "@mui/styles";
import { Flex } from "./Flex";
import { Form, Formik, useFormikContext } from "formik";
import _ from "lodash";
import { anyIsError, errorMessage } from "../utils/fetchers";
import { useQuery } from "../utils/routing";

const useStyles = makeStyles((theme: Theme) => ({
  signInContainer: {
    "& svg": {
      color: theme.palette.primary.contrastText,
    },
  },
  createAccountModal: {
    "& .MuiDialog-paper": {
      marginLeft: "max(min(32px, calc((100vw - 432px) / 2)), 0px)",
      marginRight: "max(min(32px, calc((100vw - 432px) / 2)), 0px)",
    },
  },
}));

var googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const GoogleSignInButton = ({ userExists }: { userExists?: boolean }) => {
  const dispatch = useDispatch();
  return userExists ? (
    <Button
      onClick={async () => {
        try {
          dispatch(userAuthPending());
          await firebase.auth().signOut();
          dispatch(clearAllAction.creator());
        } catch (error) {
          console.error("Log out error: ", error);
        }
      }}
      color="secondary"
      variant="contained"
    >
      Sign Out
    </Button>
  ) : (
    <Button
      color="secondary"
      onClick={() => {
        try {
          dispatch(userAuthPending());
          firebase.auth().signInWithPopup(googleAuthProvider);
        } catch (error) {
          console.error("Log in error: ", error);
        }
      }}
      variant="contained"
    >
      Login with Google
    </Button>
  );
};

// var facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

// const FacebookLoginButton = ({ userExists }: { userExists?: boolean }) => {
//   const dispatch = useDispatch();
//   const handleFacebookLogin = useCallback(async () => {
//     try {
//       dispatch(userAuthPending());
//       const result = await firebase
//         .auth()
//         .signInWithPopup(facebookAuthProvider);
//       const credential = result.credential;
//       const user = result.user;
//       console.log(credential, user);
//       // ... use the token and user data
//     } catch (error) {
//       console.error("Error logging in with Facebook:", error);
//     }
//   }, [dispatch]);
//   return (
//     <Button onClick={handleFacebookLogin} variant="contained">
//       Login with Facebook
//     </Button>
//   );
// };

const ConfirmPasswordField = () => {
  const params = useFormikContext<{
    password: string;
    confirmPassword: string;
  }>();
  useEffect(() => {
    if (params.values.confirmPassword && params.values.password) {
      params.validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.values.confirmPassword, params.values.password]);
  return (
    <TextField
      label="Confirm Password"
      name="confirmPassword"
      value={params.values.confirmPassword}
      onChange={(e) => {
        params.handleChange(e);
        if (!params.touched.confirmPassword) {
          params.setFieldTouched("confirmPassword", true);
        }
      }}
      onBlur={params.handleBlur}
      type="password"
      error={params.touched.confirmPassword && !!params.errors.confirmPassword}
      helperText={
        params.touched.confirmPassword && params.errors.confirmPassword
      }
    />
  );
};

type CreateNewAccountModalProps = {
  email: string;
};
const CreateNewAccountModal = ({ email }: CreateNewAccountModalProps) => {
  const dispatch = useDispatch();
  const user = useSelector((state: State) => state.user);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email,
        password: "",
        confirmPassword: "",
      }}
      onSubmit={async (values) => {
        console.log(values);
        dispatch(userAuthPending());
        try {
          const userCredential = await firebase
            .auth()
            .createUserWithEmailAndPassword(values.email, values.password);
          const displayName = `${values.firstName} ${values.lastName}`;
          userCredential.user?.updateProfile({ displayName });
          if (userCredential.user) {
            _.set(userCredential.user, "displayName", displayName);
            dispatch(userAuthChange(userCredential.user));
          }
          console.log(userCredential);
        } catch (error) {
          console.error("Error creating user: ", error);
          dispatch(
            userAuthError({
              message: _.get(error, "message", "Unknown error"),
              code: _.get(error, "code", 400),
            })
          );
        }
      }}
      validateOnBlur
      validateOnChange={false}
      validationSchema={Yup.object({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        email: Yup.string()
          .required("Email is required")
          .email("Invalid email address")
          .test("unique", "Email already in use", async function (value) {
            if (!value) return false;
            if (!Yup.string().email().validateSync(value)) return false;
            const user = await firebase
              .auth()
              .fetchSignInMethodsForEmail(value);
            return user.length === 0;
          }),
        password: Yup.string()
          .required("Password is required")
          .min(6, "Must be at least 6 characters")
          .max(20, "Must be less than 20 characters"),
        confirmPassword: Yup.string().test(
          "passwords-match",
          "Passwords must match",
          function (value) {
            return this.parent.password === value;
          }
        ),
      })}
    >
      {(params) => (
        <Form>
          <Flex
            flexDirection="column"
            rowGap="8px"
            p="16px"
            width="min(calc(100vw - 32px), 400px)"
          >
            <TextField
              label="First name"
              name="firstName"
              value={params.values.firstName}
              onChange={params.handleChange}
              onBlur={params.handleBlur}
              error={params.touched.firstName && !!params.errors.firstName}
              helperText={params.touched.firstName && params.errors.firstName}
              autoFocus
            />
            <TextField
              label="Last name"
              name="lastName"
              value={params.values.lastName}
              onChange={params.handleChange}
              onBlur={params.handleBlur}
              error={params.touched.lastName && !!params.errors.lastName}
              helperText={params.touched.lastName && params.errors.lastName}
            />
            <TextField
              label="Email"
              name="email"
              value={params.values.email}
              onChange={params.handleChange}
              onBlur={params.handleBlur}
              error={params.touched.email && !!params.errors.email}
              helperText={params.touched.email && params.errors.email}
            />
            <TextField
              label="Password"
              name="password"
              value={params.values.password}
              onChange={params.handleChange}
              onBlur={params.handleBlur}
              type="password"
              error={params.touched.password && !!params.errors.password}
              helperText={params.touched.password && params.errors.password}
            />
            <ConfirmPasswordField />
            <Button
              variant="contained"
              type="submit"
              onClick={params.handleSubmit as any}
              disabled={
                params.isSubmitting ||
                _.isEmpty(params.touched) ||
                !params.isValid ||
                !params.dirty
              }
            >
              Create Account
            </Button>
            {anyIsError(user) && (
              <Typography color="red">{errorMessage(user)}</Typography>
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

const CreateNewAccount = () => {
  const classes = useStyles();
  const params = useFormikContext<{ email: string; password: string }>();
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setCreateUserModalOpen(true);
          dispatch(userAuthChange(null));
        }}
      >
        Create New Account
      </Button>
      <Dialog
        open={createUserModalOpen}
        onClose={() => setCreateUserModalOpen(false)}
        className={classes.createAccountModal}
      >
        <Flex alignItems="center" justifyContent="space-between" pr="16px">
          <DialogTitle>Create New Account</DialogTitle>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setCreateUserModalOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Flex>
        <CreateNewAccountModal email={params.values.email} />
      </Dialog>
    </>
  );
};

const EmailAndPasswordSignIn = () => {
  const params = useFormikContext<{ email: string; password: string }>();
  const user = useSelector((state: State) => state.user);
  const [sentResetEmail, setSentResetEmail] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (sentResetEmail) {
      timeout = setTimeout(() => setSentResetEmail(false), 10000);
    }
    return () => clearTimeout(timeout);
  }, [sentResetEmail]);
  return (
    <Form>
      <Flex flexDirection="column" rowGap="8px">
        <TextField
          name="email"
          value={params.values.email}
          onChange={params.handleChange}
          onBlur={params.handleBlur}
          label="Email"
          error={params.touched.email && !!params.errors.email}
          helperText={
            params.touched.email && params.errors.email
              ? params.errors.email
              : ""
          }
          autoFocus
        />
        <TextField
          name="password"
          value={params.values.password}
          onChange={params.handleChange}
          onBlur={params.handleBlur}
          label="Password"
          type="password"
          error={params.touched.password && !!params.errors.password}
          helperText={params.touched.password && params.errors.password}
          fullWidth
        />

        <Button
          type="submit"
          variant="contained"
          disabled={params.isSubmitting || !params.isValid || !params.dirty}
        >
          Login
        </Button>
        {anyIsError(user) &&
          ["auth/wrong-password", "auth/invalid-credential"].includes(
            _.get(user.status, "code", "")
          ) && (
            <Button
              onClick={async () => {
                const actionCodeSettings = {
                  url: `${window.location.origin}/?email=${params.values.email}`,
                };
                const response = await firebase
                  .auth()
                  .sendPasswordResetEmail(
                    params.values.email,
                    actionCodeSettings
                  );
                console.log(response);
                setSentResetEmail(true);
              }}
              disabled={sentResetEmail}
            >
              {sentResetEmail
                ? "Reset link sent to email!"
                : "Forgot Password?"}
            </Button>
          )}
      </Flex>
    </Form>
  );
};

const AllSignIns = ({ userExists }: { userExists?: boolean }) => {
  const user = useSelector((state: State) => state.user);
  return (
    <Flex
      rowGap="16px"
      flexDirection="column"
      width="min(calc(100vw - 16px), 400px)"
    >
      {anyIsError(user) && (
        <Typography color="red">{errorMessage(user)}</Typography>
      )}
      <EmailAndPasswordSignIn />
      <Divider />
      <GoogleSignInButton userExists={userExists} />
      {/* We need a business account with facebook to get other users' emails. */}
      {/* <FacebookLoginButton userExists={userExists} /> */}
      <Divider />
      <CreateNewAccount />
    </Flex>
  );
};

export const SignIn = () => {
  const classes = useStyles();
  const user = useSelector((state: State) => state.user);
  const email = useQuery().get("email") || "";
  const dispatch = useDispatch();
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      dispatch(userAuthChange(authUser));
    });
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <div>
      <Formik
        initialValues={{ email, password: "" }}
        onSubmit={async ({ email, password }) => {
          try {
            dispatch(userAuthPending());
            await firebase.auth().signInWithEmailAndPassword(email, password);
          } catch (error) {
            dispatch(
              userAuthError({
                message: _.get(error, "message", "Unknown error"),
                code: _.get(error, "code", 400),
              })
            );
            console.error("Error logging in with email and password: ", error);
          }
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .required("Email is required")
            .email("That doesn't look like an email address"),
          password: Yup.string().required("Password is required"),
        })}
      >
        {(params) => (
          <FetchedComponent
            resource={user}
            IdleComponent={AllSignIns as any}
            ErrorComponent={AllSignIns as any}
            circularLoading
            loadingClassName={classes.signInContainer}
          >
            {(data) => <AllSignIns userExists={!!data} />}
          </FetchedComponent>
        )}
      </Formik>
    </div>
  );
};
