import { ArrowForward, ExpandMore, Group, Tune } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  IconButton,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Chip,
  FormGroup,
  FormControlLabel,
  Grid,
  Slider,
  TextField,
  CardActions,
  Button,
  Switch,
  Theme,
} from "@mui/material";
import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import { forwardRef, Ref, useState, useMemo } from "react";
import * as Yup from "yup";
import { ExchangeEvent } from "../models/functions";
import { useUser } from "../redux/selectors";
import {
  updateExchangeEventAction,
  createExchangeEventAction,
  deleteExchangeEventAction,
  emptyExchangeEvent,
} from "../redux/slices/exchangeEvent";
import { useDispatcher } from "../utils/fetchers";
import { Flex } from "./Flex";
import { MultiTextField } from "./inputs/MultiTextField";
import { CARD_WIDTH } from "../constants/style";
import { EditableField } from "./EditableField";
import {
  formatTimestampMMDDYYYY,
  formatTimestampYYYY_MM_DD,
} from "../utils/date";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: `min(calc(100vw - 8px), ${CARD_WIDTH}px)`,
    overflowY: ({ inModal }: { inModal?: boolean }) =>
      inModal ? "auto" : "hidden",
  },
}));

type ExchangeEventCardProps = {
  exchangeEvent: ExchangeEvent;
  userOptions: string[];
  initialEditMode?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  inModal?: boolean;
};

export const ExchangeEventCard = forwardRef(
  (
    {
      initialEditMode = false,
      onCancel = _.noop,
      onSave = _.noop,
      exchangeEvent: { updatedAt, id, ...exchangeMetadata },
      userOptions,
      inModal,
    }: ExchangeEventCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const classes = useStyles({ inModal });
    const user = useUser();
    const {
      name,
      description,
      users: userEmails,
      date,
      options,
    } = exchangeMetadata;
    const [editMode, setEditMode] = useState(initialEditMode);
    const [confirmingDelete, setConfirmingDelete] = useState(false);
    const [expandUsers, setExpandUsers] = useState(false);
    const updateExchangeEvent = useDispatcher(updateExchangeEventAction);
    const createExchangeEvent = useDispatcher(createExchangeEventAction);
    const deleteExchangeEvent = useDispatcher(deleteExchangeEventAction);
    const isAuthor = user?.uid === exchangeMetadata.author.uid;
    const eventMetadataFormValues = useMemo(
      () => ({
        eventName: name,
        description,
        date,
        users: userEmails,
        options,
      }),
      [name, description, date, userEmails, options]
    );
    return (
      <Formik
        initialValues={eventMetadataFormValues}
        onSubmit={({ eventName: name, description, date, users, options }) => {
          if (!!id) {
            updateExchangeEvent({
              id,
              name,
              description,
              date,
              users,
              options,
            });
          } else {
            createExchangeEvent({
              ..._.cloneDeep(emptyExchangeEvent),
              name,
              description,
              date,
              users,
              options,
            });
          }
          setEditMode(false);
        }}
        validationSchema={Yup.object({
          eventName: Yup.string().required("Required"),
          description: Yup.string(),
          date: Yup.number(),
          users: Yup.array()
            .of(
              Yup.string().test({
                name: "validate-email",
                test: (value, context) => {
                  if (!Yup.string().email().isValidSync(value)) {
                    return context.createError({
                      message: `${value} is not a valid email address`,
                    });
                  }
                  return true;
                },
              })
            )
            .max(50, "Max 50 users"),
        })}
      >
        {(props) => {
          const usersInEvent = Array.from(
            new Set([exchangeMetadata.author.email, ...userEmails])
          );
          return (
            <Card className={classes.card} elevation={3} ref={ref}>
              <CardContent>
                <Flex flexDirection="column" gap="16px">
                  <Flex justifyContent="space-between" alignItems="center">
                    <EditableField
                      variant="h5"
                      fieldName="eventName"
                      canEdit={editMode}
                      label="Event Name"
                    />
                    {!editMode && (
                      <a href={`/gift-exchange-event/${id}`}>
                        <IconButton>
                          <ArrowForward />
                        </IconButton>
                      </a>
                    )}
                  </Flex>
                  <EditableField
                    variant="subtitle1"
                    fieldName="description"
                    canEdit={editMode}
                    multiline
                    label="Description"
                  />
                  <Flex flexDirection="column">
                    <Accordion
                      expanded={expandUsers}
                      onChange={(evt, expanded) => setExpandUsers(expanded)}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Flex gap="8px">
                          <Group />
                          <Typography>{`${usersInEvent.length} user${
                            usersInEvent.length === 1 ? "" : "s"
                          } (including you)`}</Typography>
                        </Flex>
                      </AccordionSummary>
                      <AccordionDetails>
                        {editMode ? (
                          <MultiTextField
                            label="Users"
                            placeholder="Enter an email..."
                            value={props.values.users}
                            onChange={(e, newValue) => {
                              props.setFieldValue("users", newValue);
                            }}
                            filterSelectedOptions
                            options={userOptions}
                            freeSolo
                            confirmKeys={[",", "Space"]}
                            helperText={
                              props.errors.users
                                ? props.errors.users
                                    .toString()
                                    .split(",")
                                    .filter(Boolean)[0]
                                : "Enter user email addresses"
                            }
                            error={!!props.errors.users}
                            filterOptions={(options, { inputValue }) =>
                              options.filter((option) => {
                                const inputVal = inputValue.toLowerCase();
                                const optionVal = option.toLowerCase();
                                return optionVal.includes(inputVal);
                              })
                            }
                          />
                        ) : (
                          <Flex gap="8px" flexWrap="wrap">
                            {_.map(usersInEvent, (user) => (
                              <Chip label={user} key={user} />
                            ))}
                          </Flex>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Flex gap="8px">
                          <Tune />
                          <Typography>Options</Typography>
                        </Flex>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={!editMode}
                                checked={props.values.options.selfListRequired}
                                onChange={(evt, checked) =>
                                  props.setFieldValue(
                                    "options.selfListRequired",
                                    checked
                                  )
                                }
                              />
                            }
                            label="Every user must make a list"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={!editMode}
                                checked={props.values.options.extraListsAllowed}
                                onChange={(evt, checked) =>
                                  props.setFieldValue(
                                    "options.extraListsAllowed",
                                    checked
                                  )
                                }
                              />
                            }
                            label="Extra Lists are allowed"
                          />
                          <Flex flexDirection="column">
                            <Typography>
                              Maximum number of extra lists
                            </Typography>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs>
                                <Slider
                                  disabled={!editMode}
                                  value={props.values.options.maxExtraLists}
                                  onChange={(evt, value) =>
                                    props.setFieldValue(
                                      "options.maxExtraLists",
                                      value
                                    )
                                  }
                                  step={1}
                                  min={1}
                                  max={50}
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  disabled={!editMode}
                                  variant="standard"
                                  type="number"
                                  value={props.values.options.maxExtraLists}
                                  onChange={(evt) =>
                                    props.setFieldValue(
                                      "options.maxExtraLists",
                                      +evt.target.value
                                    )
                                  }
                                  onBlur={(evt) => {
                                    props.setFieldValue(
                                      "options.maxExtraLists",
                                      Math.max(
                                        Math.min(
                                          props.values.options.maxExtraLists,
                                          50
                                        ),
                                        1
                                      )
                                    );
                                  }}
                                  inputProps={{
                                    step: 1,
                                    min: 1,
                                    max: 50,
                                    type: "number",
                                    "aria-labelledby": "input-slider",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Flex>
                          <Flex flexDirection="column">
                            <Typography>
                              Maximum number of ideas per list
                            </Typography>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs>
                                <Slider
                                  disabled={!editMode}
                                  value={props.values.options.maxIdeasPerList}
                                  onChange={(evt, value) =>
                                    props.setFieldValue(
                                      "options.maxIdeasPerList",
                                      value
                                    )
                                  }
                                  step={1}
                                  min={1}
                                  max={50}
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  disabled={!editMode}
                                  variant="standard"
                                  type="number"
                                  value={props.values.options.maxIdeasPerList}
                                  onChange={(evt) =>
                                    props.setFieldValue(
                                      "options.maxIdeasPerList",
                                      +evt.target.value
                                    )
                                  }
                                  onBlur={(evt) => {
                                    props.setFieldValue(
                                      "options.maxIdeasPerList",
                                      Math.max(
                                        Math.min(
                                          props.values.options.maxIdeasPerList,
                                          50
                                        ),
                                        1
                                      )
                                    );
                                  }}
                                  inputProps={{
                                    step: 1,
                                    min: 1,
                                    max: 50,
                                    type: "number",
                                    "aria-labelledby": "input-slider",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Flex>
                        </FormGroup>
                      </AccordionDetails>
                    </Accordion>
                  </Flex>
                  <EditableField
                    fieldName="date"
                    canEdit={editMode}
                    label="Event date"
                    toInputValue={formatTimestampYYYY_MM_DD}
                    toDisplayValue={formatTimestampMMDDYYYY}
                    fromInputValue={(dateStr) =>
                      moment(dateStr).toDate().getTime()
                    }
                    type="date"
                  />
                  <Typography>{`Last updated: ${moment(
                    updatedAt
                  ).calendar()}`}</Typography>
                </Flex>
              </CardContent>
              {isAuthor && (
                <CardActions>
                  <Flex justifyContent="space-between" flexGrow={1}>
                    {editMode ? (
                      <>
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={() => {
                            props.submitForm();
                            onSave();
                          }}
                          disabled={
                            !_.isEmpty(props.errors) ||
                            _.isEqual(props.values, eventMetadataFormValues)
                          }
                        >
                          {id ? "Save" : "Create"}
                        </Button>
                        <Button
                          onClick={() => {
                            if (id) setEditMode(false);
                            onCancel();
                          }}
                          variant="outlined"
                        >
                          Cancel
                        </Button>
                      </>
                    ) : confirmingDelete && id ? (
                      <>
                        <Button
                          color="error"
                          variant="contained"
                          onClick={() =>
                            deleteExchangeEvent({ exchangeEventId: id })
                          }
                        >
                          Delete
                        </Button>
                        <Typography>Are you sure?</Typography>
                        <Button
                          onClick={() => {
                            setConfirmingDelete(false);
                          }}
                          variant="outlined"
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          color="error"
                          variant="outlined"
                          onClick={() => setConfirmingDelete(true)}
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={() => {
                            setEditMode(true);
                            setExpandUsers(true);
                          }}
                          variant="outlined"
                        >
                          Edit
                        </Button>
                      </>
                    )}
                  </Flex>
                </CardActions>
              )}
            </Card>
          );
        }}
      </Formik>
    );
  }
);
