import { TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Flex } from "./Flex";
import { CalendarMonth } from "@mui/icons-material";
import { useField } from "formik";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  h5: {
    "&&": {
      ...theme.typography.h5,
    },
  },
  subtitle1: {
    ...theme.typography.subtitle1,
  },
}));

export type EditableFieldProps = {
  canEdit: boolean;
  fieldName: string;
  variant?: "h5" | "subtitle1";
  multiline?: boolean;
  label?: string;
  type?: React.HTMLInputTypeAttribute;
  toInputValue?: (value: any) => string | number;
  toDisplayValue?: (value: any) => string | number;
  fromInputValue?: (value: string | number) => any;
};

export const EditableField = ({
  canEdit,
  fieldName,
  variant,
  multiline,
  label,
  type,
  toInputValue = _.identity,
  toDisplayValue = _.identity,
  fromInputValue = _.identity,
}: EditableFieldProps) => {
  const classes = useStyles();
  const [inputProps, metadata, helpers] = useField(fieldName);
  const value = toDisplayValue(inputProps.value);
  return canEdit ? (
    <TextField
      autoFocus={fieldName === "eventName"}
      inputProps={
        variant
          ? {
              className: classes[variant],
            }
          : undefined
      }
      label={label}
      onClick={(evt) => evt.stopPropagation()}
      multiline={multiline}
      fullWidth
      variant="standard"
      type={type}
      {...inputProps}
      {...(fromInputValue
        ? {
            onChange: (evt) =>
              helpers.setValue(fromInputValue(evt.target.value)),
          }
        : {})}
      value={toInputValue(inputProps.value)}
      error={!!metadata.error}
      helperText={metadata.error ? metadata.error : undefined}
    />
  ) : value !== "" ? (
    <Flex gap="8px">
      {fieldName === "date" && (
        <>
          <CalendarMonth />
          <Typography fontWeight={500}>Event Date:</Typography>
        </>
      )}
      <Typography variant={variant}>{value}</Typography>
    </Flex>
  ) : null;
};
