import {
  RemoveCircleOutline,
  ArrowForwardIos,
  ArrowBackIos,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  IconButton,
  Paper,
  Switch,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useExchangeEvent } from "../../redux/selectors";
import { Flex } from "../Flex";
import { ModalContext, ModalType } from "./ModalContext";
import { Formik, useFormikContext } from "formik";
import { MultiTextField } from "../inputs/MultiTextField";
import { useParams } from "react-router-dom";
import { ExchangeEvent } from "@functions/models";
import {
  generateBidrectionalMatches,
  generateLoopMatches,
  generateMatches,
} from "../../utils/matches";
import classNames from "classnames";
import _ from "lodash";
import { useDispatcher } from "../../utils/fetchers";
import { updateExchangeEventAction } from "../../redux/slices/exchangeEvent";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    "& > .MuiDialog-container > .MuiDialog-paper": {
      minWidth: "min(100vw, 400px)",
      [theme.breakpoints.down(960)]: {
        margin: 0,
        flexGrow: 1,
        "& > div": {
          padding: 8,
        },
      },
    },
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    "& > .MuiChip-root": {
      [theme.breakpoints.down(960)]: {
        width: "40%",
      },
      flexGrow: 1,
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  matchContainer: {
    display: "grid",
    rowGap: "8px",
    alignItems: "center",
    gridTemplateColumns: "1fr 24px 1fr",
    "& > .MuiPaper-root": {
      display: "flex",
      gap: "8px",
      padding: "4px",
      flexWrap: "wrap",
    },
  },
  flexEnd: {
    justifyContent: "flex-end",
  },
  droppable: {
    display: "flex",
    gap: "8px",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  chipPaper: {
    display: "flex",
    gap: "8px",
    padding: "4px",
    flexWrap: "wrap",
  },
  manualArrows: {
    gap: "10px",
  },
  pointerCursor: {
    cursor: "pointer",
  },
}));

const INITIAL_FORM_VALUES = {
  type: "noTwoWay" as ExchangeEvent["drawNames"]["type"],
  gifters: [[]] as string[][],
};

type DrawNamesFormValues = typeof INITIAL_FORM_VALUES;

type NamesFormProps = {
  generateMatchesOnClick: (
    type: "manual" | "noTwoWay" | "someTwoWay" | "allTwoWay" | "oneLoop",
    gifters: string[][]
  ) => void;
};
export const NamesForm = ({ generateMatchesOnClick }: NamesFormProps) => {
  const { exchangeEvent: exchangeEventUrlParam } = useParams<{
    exchangeEvent: string;
  }>();
  const exchangeEvent = useExchangeEvent(exchangeEventUrlParam);

  const { values, setFieldValue, errors } =
    useFormikContext<DrawNamesFormValues>();

  const userOptions = useMemo(() => {
    return exchangeEvent
      ? _.without(
          _.uniq([
            exchangeEvent.data.author.email,
            ...exchangeEvent.data.users,
          ]),
          ...values.gifters.flatMap((gifters) => gifters)
        )
      : [];
  }, [exchangeEvent, values.gifters]);
  const [addGifterInput, setAddGifterInput] = useState<string[]>([]);
  return (
    <Flex
      flexDirection="column"
      minWidth="300px"
      gap="16px"
      boxSizing="border-box"
    >
      <Typography variant="h4">Draw Names</Typography>
      <Flex flexDirection="column" gap="16px">
        <Flex flexDirection="column" gap="16px">
          {values.gifters.map((gifter, index) => {
            return (
              <Flex gap="8px" key={`gifter-${index}`}>
                <MultiTextField
                  variant="outlined"
                  fullWidth
                  label={`Gifter ${index + 1}`}
                  placeholder="Enter one or more emails..."
                  value={gifter}
                  onChange={(e, newValue) => {
                    setFieldValue(`gifters[${index}]`, newValue);
                  }}
                  options={userOptions}
                  filterSelectedOptions
                  freeSolo
                  confirmKeys={[",", "Space"]}
                  helperText={
                    errors?.gifters?.[index]
                      ? errors.gifters[index]
                          .toString()
                          .split(",")
                          .filter(Boolean)[0]
                      : `Enter email addresses for gifter ${index + 1}`
                  }
                  error={!!errors?.gifters?.[index]}
                />
                <div>
                  <IconButton
                    onClick={() => {
                      const newGifters = [...values.gifters];
                      newGifters.splice(index, 1);
                      setFieldValue("gifters", newGifters);
                      generateMatchesOnClick(values.type, newGifters);
                    }}
                  >
                    <RemoveCircleOutline color="error" />
                  </IconButton>
                </div>
              </Flex>
            );
          })}
          <MultiTextField
            value={addGifterInput}
            onChange={(e, newValue) => {
              setAddGifterInput(newValue);
              if (newValue.length > 0) {
                const newGifters = [...values.gifters, newValue];
                setFieldValue("gifters", newGifters);
                setAddGifterInput([]);
                generateMatchesOnClick(values.type, newGifters);
              }
            }}
            variant="outlined"
            fullWidth
            label={`Add Gifter`}
            placeholder="Enter one or more emails..."
            options={userOptions}
            filterSelectedOptions
            freeSolo
            confirmKeys={[",", "Space"]}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

type DragAndDropProps = {
  localMatches: number[];
  setLocalMatches: Dispatch<SetStateAction<number[]>>;
};
const DragAndDrop = ({ localMatches, setLocalMatches }: DragAndDropProps) => {
  const classes = useStyles();
  const { values } = useFormikContext<DrawNamesFormValues>();
  const invertLocalMatches = useMemo(() => {
    return localMatches.reduce((acc, receiverIndex, gifterIndex) => {
      acc[receiverIndex] = gifterIndex;
      return acc;
    }, [] as number[]);
  }, [localMatches]);
  const [swapWith, setSwapWith] = useState<number | null>(null);
  const onClickHandler = useCallback(
    (idx: number) => {
      if (swapWith === null) {
        setSwapWith(idx);
      } else {
        const newInvertedMatches = [...invertLocalMatches];
        const tmp = newInvertedMatches[swapWith];
        newInvertedMatches[swapWith] = newInvertedMatches[idx];
        newInvertedMatches[idx] = tmp;
        const newMatches = newInvertedMatches.reduce(
          (acc, gifterIndex, receiverIndex) => {
            acc[gifterIndex] = receiverIndex;
            return acc;
          },
          [] as number[]
        );
        setLocalMatches(newMatches);
        setSwapWith(null);
      }
    },
    [invertLocalMatches, setLocalMatches, swapWith]
  );
  return (
    <Flex>
      <div className={classNames(classes.droppable, {})}>
        {invertLocalMatches.map((gifterIndex, receiverIndex) => {
          const gifterTeam = values.gifters[gifterIndex];
          const receiverTeam = values.gifters[receiverIndex];
          return (
            <Flex
              alignItems="center"
              justifyContent="space-between"
              className={classes.matchContainer}
              key={gifterTeam.toString()}
            >
              <Paper
                variant="outlined"
                className={classes.chipPaper}
                onClick={() => onClickHandler(receiverIndex)}
              >
                {gifterTeam.map((email) => (
                  <Chip
                    key={email}
                    label={email}
                    size="small"
                    color={swapWith === receiverIndex ? "primary" : "secondary"}
                    className={classes.pointerCursor}
                  />
                ))}
              </Paper>
              <ArrowForwardIos />
              <Paper
                variant="outlined"
                className={classNames(classes.flexEnd, classes.chipPaper)}
              >
                {receiverTeam.map((email) => (
                  <Chip
                    key={email}
                    label={email}
                    size="small"
                    color="secondary"
                  />
                ))}
              </Paper>
            </Flex>
          );
        })}
      </div>
    </Flex>
  );
};

const ModalBody = () => {
  const classes = useStyles();
  const { setModal } = useContext(ModalContext);
  const { exchangeEvent: exchangeEventUrlParam } = useParams<{
    exchangeEvent: string;
  }>();
  const exchangeEvent = useExchangeEvent(exchangeEventUrlParam);
  const updateExchangeEvent = useDispatcher(updateExchangeEventAction);
  const [step, setStep] = useState<"form" | "matches">(
    exchangeEvent?.data.drawNames.matches.length ? "matches" : "form"
  );
  const [localMatches, setLocalMatches] = useState(
    exchangeEvent?.data.drawNames.matches ?? []
  );
  const [showMatches, setShowMatches] = useState(true);
  const initialFormValues = useMemo(() => {
    if (!exchangeEvent) {
      return INITIAL_FORM_VALUES;
    }
    return {
      type: exchangeEvent.data.drawNames.type,
      gifters: exchangeEvent.data.drawNames.gifters.map(
        (gifter) => gifter.emails
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeEvent?.data.drawNames]);

  const generateMatchesOnClick = useCallback(
    (
      type: "manual" | "noTwoWay" | "someTwoWay" | "allTwoWay" | "oneLoop",
      gifters: string[][]
    ) => {
      let matchIndices: number[] = [];
      if (type === "noTwoWay") {
        matchIndices = generateMatches(gifters.length);
      } else if (type === "someTwoWay") {
        matchIndices = generateMatches(gifters.length, true);
      } else if (type === "allTwoWay") {
        matchIndices = generateBidrectionalMatches(gifters.length);
      } else if (type === "oneLoop") {
        matchIndices = generateLoopMatches(gifters.length);
      } else {
        matchIndices = generateMatches(gifters.length);
      }
      setLocalMatches(matchIndices);
    },
    []
  );

  const sortedMatches = useMemo(() => {
    const unvisited = new Set<number>(_.range(localMatches.length));
    const sortedMatches = [];
    while (unvisited.size > 0) {
      const current = unvisited.values().next().value;
      unvisited.delete(current);
      sortedMatches.push(current);
      let next = localMatches[current];
      while (next !== current) {
        unvisited.delete(next);
        sortedMatches.push(next);
        next = localMatches[next];
      }
    }
    return sortedMatches;
  }, [localMatches]);

  return (
    <Formik initialValues={initialFormValues} onSubmit={() => {}}>
      {({ values, setFieldValue }) => (
        <>
          {step === "form" ? (
            <Flex flexDirection="column" p="32px" gap="32px">
              <NamesForm generateMatchesOnClick={generateMatchesOnClick} />
              <Flex gap="8px">
                <Button
                  onClick={() => {
                    setModal(null);
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setStep("matches");
                  }}
                  variant="contained"
                  className={classes.flexGrow}
                >
                  Next
                  <ArrowForwardIos />
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Flex flexDirection="column" p="32px" gap="16px">
              <Typography variant="h4">Matches</Typography>
              <Flex gap="16px" flexDirection="column">
                <Flex flexDirection="column" gap="8px">
                  <Flex justifyContent="space-between">
                    <Typography>Auto or Manual</Typography>
                    {values.type === "manual" ? (
                      <div />
                    ) : (
                      <Typography>Show Matches</Typography>
                    )}
                  </Flex>
                  <Flex justifyContent="space-between">
                    <ButtonGroup>
                      <Button
                        variant={
                          values.type !== "manual" ? "contained" : "outlined"
                        }
                        onClick={() => setFieldValue("type", "noTwoWay")}
                      >
                        Auto
                      </Button>
                      <Button
                        variant={
                          values.type === "manual" ? "contained" : "outlined"
                        }
                        onClick={() => setFieldValue("type", "manual")}
                      >
                        Manual
                      </Button>
                    </ButtonGroup>
                    {values.type === "manual" ? (
                      <div />
                    ) : (
                      <Switch
                        onChange={(evt, checked) => setShowMatches(checked)}
                        checked={showMatches}
                      />
                    )}
                  </Flex>
                </Flex>
                {values.type !== "manual" ? (
                  <>
                    <Typography>Match Connectivity</Typography>
                    <div className={classes.chipContainer}>
                      <Chip
                        label="No Two-Way"
                        color={
                          values.type === "noTwoWay" ? "primary" : "default"
                        }
                        onClick={() => setFieldValue("type", "noTwoWay")}
                      />
                      <Chip
                        label="Some Two-Way"
                        color={
                          values.type === "someTwoWay" ? "primary" : "default"
                        }
                        onClick={() => setFieldValue("type", "someTwoWay")}
                      />
                      <Chip
                        label="All Two-Ways"
                        color={
                          values.type === "allTwoWay" ? "primary" : "default"
                        }
                        onClick={() => setFieldValue("type", "allTwoWay")}
                      />
                      <Chip
                        label="One Loop"
                        color={
                          values.type === "oneLoop" ? "primary" : "default"
                        }
                        onClick={() => setFieldValue("type", "oneLoop")}
                      />
                    </div>
                  </>
                ) : (
                  <Typography>Select 2 gifters to swap</Typography>
                )}
                {values.type === "manual" ? (
                  <DragAndDrop
                    localMatches={localMatches}
                    setLocalMatches={setLocalMatches}
                  />
                ) : (
                  <Flex flexDirection="column" gap="16px">
                    <div className={classes.matchContainer}>
                      {sortedMatches.map((gifterIndex) => {
                        const receiverIndex = localMatches[gifterIndex];
                        const gifters = values.gifters[gifterIndex];
                        const receivers = showMatches
                          ? values.gifters[receiverIndex]
                          : ["********@****.***"];
                        return (
                          <Fragment key={gifters.toString()}>
                            <Paper
                              variant="outlined"
                              className={classes.chipPaper}
                            >
                              {gifters.map((gifter) => (
                                <Chip
                                  color="secondary"
                                  key={gifter}
                                  label={gifter}
                                  size="small"
                                />
                              ))}
                            </Paper>
                            <ArrowForwardIos />
                            <Paper
                              variant="outlined"
                              className={classes.flexEnd}
                            >
                              {receivers.map((receiver) => (
                                <Chip
                                  color="secondary"
                                  key={receiver}
                                  label={receiver}
                                  size="small"
                                />
                              ))}
                            </Paper>
                          </Fragment>
                        );
                      })}
                    </div>
                  </Flex>
                )}
                {values.type !== "manual" && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      generateMatchesOnClick(values.type, values.gifters);
                    }}
                  >
                    {localMatches.length === 0 ? "Generate" : "Re-Generate"}
                  </Button>
                )}
                <Flex gap="8px">
                  <Button variant="outlined" onClick={() => setStep("form")}>
                    <ArrowBackIos />
                    Go Back
                  </Button>

                  <Button
                    className={classes.flexGrow}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      updateExchangeEvent({
                        id: exchangeEventUrlParam,
                        drawNames: {
                          type: values.type,
                          gifters: values.gifters.map((emails) => ({
                            emails,
                          })),
                          matches: localMatches,
                        },
                      });
                      setModal(null);
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}
        </>
      )}
    </Formik>
  );
};

export const DrawNamesModal = () => {
  const classes = useStyles();
  const { modal, setModal } = useContext(ModalContext);

  return (
    <Dialog
      open={modal === ModalType.DrawNames}
      onClose={() => setModal(null)}
      className={classes.modal}
    >
      <ModalBody />
    </Dialog>
  );
};
