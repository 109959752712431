import { PaletteMode } from "@mui/material";
import _ from "lodash";
import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export const DEFAULT_USER_SETTINGS = {
  onlyShowMatches: false,
  hideMarkedIdeas: false,
  themeMode: "light" as PaletteMode,
};

export type SettingsState = typeof DEFAULT_USER_SETTINGS;

export interface LocalStorageState {
  gifterSettings: SettingsState;
}
export enum LocalStorageKey {
  SETTINGS = "gifterSettings",
}

const DEFAULT_LOCAL_STORAGE_VALUES: LocalStorageState = {
  [LocalStorageKey.SETTINGS]: DEFAULT_USER_SETTINGS,
};

const tryParse = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

const getLocalStorageItem = <K extends LocalStorageKey>(
  key: K
): LocalStorageState[K] => {
  return (
    tryParse(window.localStorage.getItem(key)!) ||
    DEFAULT_LOCAL_STORAGE_VALUES[key]
  );
};

// We need setLocalStorageItem to have a static reference so that it doesn't cause cyclical
// renders when the localStorage state is set and a new context value is
// created. We also need it to store a .refreshFn that gets called whenever the
// state is set so that we can exaclty control when the context value changes
type SetLocalStorageStateType = (<K extends LocalStorageKey>(
  key: K,
  value: SetStateAction<LocalStorageState[K]>
) => void) & { refreshFn?: () => void };

const setLocalStorageItem: SetLocalStorageStateType = <
  K extends LocalStorageKey
>(
  key: K,
  value: SetStateAction<LocalStorageState[K]>
) => {
  const currentState = getLocalStorageItem(key);
  const valueToStore = value instanceof Function ? value(currentState) : value;
  const stringValueToStore = JSON.stringify(valueToStore);
  if (JSON.stringify(currentState) !== stringValueToStore) {
    window.localStorage.setItem(key, stringValueToStore);
    setLocalStorageItem.refreshFn?.();
  }
};

const localStorageGetterSetter = () => {
  return {
    getLocalStorageItem,
    setLocalStorageItem,
  };
};

const LocalStorageContext = createContext(localStorageGetterSetter());

export const LocalStorageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [togglingDependency, dispatchUpdate] = useState(true);
  useEffect(() => {
    // Set the static .refreshFn property on the setLocalStorageItem global function to
    // toggle this components local state so that the context value updates
    // whenever the setLocalStorageItem is called.
    setLocalStorageItem.refreshFn = () => {
      return dispatchUpdate((x) => !x);
    };
  }, []);
  const localStorageContextValue = useMemo(
    () => localStorageGetterSetter(),
    // Disable exhaustive-deps lint on next line because that's the whole purpose of this toggle, to change the value to the Provider.
    [togglingDependency] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <LocalStorageContext.Provider value={localStorageContextValue}>
      {children}
    </LocalStorageContext.Provider>
  );
};

const useLocalStorage = <K extends LocalStorageKey>(
  key: K
): [LocalStorageState[K], Dispatch<SetStateAction<LocalStorageState[K]>>] => {
  const getterSetter = useContext(LocalStorageContext);
  return useMemo(() => {
    return [
      getterSetter.getLocalStorageItem(key),
      (value) => getterSetter.setLocalStorageItem(key, value),
    ];
  }, [key, getterSetter]);
};

export const useUserSettings = (): [
  SettingsState,
  Dispatch<SetStateAction<SettingsState>>
] => {
  const [settings, setSettings] = useLocalStorage(LocalStorageKey.SETTINGS);
  return [_.defaults(settings, DEFAULT_USER_SETTINGS), setSettings];
};

export const useUserSettingsValue = <K extends keyof SettingsState>(
  ...keys: K[]
): {
  [key in K]: SettingsState[key];
} & {
  setSettingsValue: <T extends K>(key: T, value: SettingsState[T]) => void;
} => {
  const [settings, setSettings] = useUserSettings();
  const setSettingsValue = useCallback(
    (key: K, value: SettingsState[K]) => {
      setSettings((prevSettings: SettingsState) => ({
        ...prevSettings,
        [key]: value,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSettings]
  );
  return {
    ..._.pick(settings, keys),
    setSettingsValue,
  };
};
