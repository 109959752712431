import firebase from "firebase/compat/app";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  colors,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import _ from "lodash";
import { useCallback, useState } from "react";
import { WishList, ExchangeEvent } from "../models/functions";
import { useUser } from "../redux/selectors";
import {
  addIdeaAction,
  CREATING_WISHLIST_ID,
  deleteWishListAction,
  updateIdeaOrderAction,
  updateWishListMetadataAction,
} from "../redux/slices/wishLists";
import { useDispatcher } from "../utils/fetchers";
import { AddButtonWithText } from "./AddButtonWithText";
import { DeleteButtonWithConfirmation } from "./DeleteButtonWithConfirmation";
import { Flex } from "./Flex";
import { IdeaCard } from "./IdeaCard";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useUserSettingsValue } from "../utils/localStorage";

const useStyles = makeStyles((theme: Theme) => ({
  titleInput: {
    "&&": {
      ...theme.typography.h5,
    },
  },
  notesInput: {
    ...theme.typography.subtitle1,
  },
  wishListContainer: {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.primary.light
        : theme.palette.primary.dark,
    borderRadius: 4,
  },
  wishListTitle: {
    "&&": {
      background: "transparent",
    },
  },
  extraWishListContainer: {
    backgroundColor:
      theme.palette.mode === "light" ? colors.purple[50] : colors.purple[900],
  },
  ownWishListContainer: {
    backgroundColor:
      theme.palette.mode === "light" ? colors.amber[50] : colors.teal[900],
  },
  yourListText: {
    width: 44,
  },
  match: {
    boxShadow: `0px 3px 3px -2px ${theme.palette.primary.main},0px 3px 4px 0px ${theme.palette.primary.main},0px 1px 8px 0px ${theme.palette.primary.main}`,
  },
}));

type TitleInputProps = {
  list: WishList;
  canEdit: boolean;
  fieldName: "title" | "notes";
};

const EditableField = ({ list, canEdit, fieldName }: TitleInputProps) => {
  const classes = useStyles();
  const user = useUser();
  const [text, setText] = useState(list[fieldName]);
  const updateListMetadata = useDispatcher(updateWishListMetadataAction);
  const isListAuthor = list.author.uid === user?.uid;
  return isListAuthor && canEdit ? (
    <TextField
      inputProps={{
        className:
          fieldName === "title" ? classes.titleInput : classes.notesInput,
      }}
      value={text}
      onChange={(e) => {
        setText(e.target.value);
      }}
      onBlur={() => {
        // Does this cover everything?  What if the user somehow doesn't blur, but they expect it to be saved?
        if (text !== list[fieldName]) {
          updateListMetadata({
            id: list.id,
            [fieldName]: text,
          });
        }
      }}
      label={fieldName === "notes" ? "Notes" : undefined}
      onClick={(evt) => evt.stopPropagation()}
      multiline={fieldName === "notes"}
      fullWidth
      variant="standard"
    />
  ) : (
    <Typography variant={fieldName === "title" ? "h5" : "subtitle1"}>
      {list[fieldName]}
    </Typography>
  );
};

type WishListCardProps = {
  event: ExchangeEvent;
  list: WishList;
  user: firebase.User;
};
export const WishListCard = ({ event, list, user }: WishListCardProps) => {
  const classes = useStyles();
  const [listExpanded, setListExpanded] = useState(true);
  const [expandedIdeaId, setExpandedIdeaId] = useState<string | null>(null);
  const { hideMarkedIdeas } = useUserSettingsValue("hideMarkedIdeas");
  const addIdea = useDispatcher(addIdeaAction);
  const deleteWishlist = useDispatcher(deleteWishListAction);
  const updateIdeaOrder = useDispatcher(updateIdeaOrderAction);
  const addIdeaFromTitle = useCallback(
    (title: string) => {
      return addIdea({
        wishListId: list.id,
        idea: { title, description: "" },
      });
    },
    [addIdea, list.id]
  );
  const ownList = list.author.uid === user?.uid && !list.isExtra;
  const gifterIndex = event.drawNames.gifters.findIndex(({ emails }) =>
    emails.includes(user?.email || "")
  );
  const isMatch =
    gifterIndex === -1 || list.isExtra
      ? false
      : event.drawNames.gifters[
          event.drawNames.matches[gifterIndex]
        ].emails.includes(list.author.email);

  const ideaOrder = list.ideaOrder.filter((ideaId) => {
    if (!list.ideas[ideaId]) return false;
    if (hideMarkedIdeas) {
      const idea = list.ideas[ideaId];
      if (idea.mark?.status === "completed") {
        return false;
      }
    }
    return true;
  });

  return (
    <DragDropContext
      onDragEnd={(result, provided) => {
        if (!result.destination) {
          return;
        }
        const newOrder = list.ideaOrder.slice();
        const tmp = newOrder[result.source.index];
        newOrder[result.source.index] = newOrder[result.destination.index];
        newOrder[result.destination.index] = tmp;
        updateIdeaOrder({
          wishListId: list.id,
          ideaOrder: newOrder,
        });
      }}
    >
      <Accordion
        expanded={listExpanded}
        onChange={(evt, expanded) => setListExpanded(expanded)}
        className={classNames(classes.wishListContainer, {
          [classes.extraWishListContainer]: list.isExtra,
          [classes.ownWishListContainer]: ownList,
          [classes.match]: isMatch,
        })}
        color="primary"
        elevation={3}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          color="primary"
          className={classes.wishListTitle}
        >
          {ownList && (
            <Typography variant="caption" className={classes.yourListText}>
              Your List
            </Typography>
          )}
          <EditableField list={list} canEdit={listExpanded} fieldName="title" />
        </AccordionSummary>
        <AccordionDetails>
          <Flex flexDirection="column" gap="16px">
            <EditableField
              list={list}
              canEdit={listExpanded}
              fieldName="notes"
            />
            <Flex flexDirection="column">
              <Droppable droppableId={`droppable-${list.id}`}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {ideaOrder.map((ideaId, index) => {
                      const idea = list.ideas[ideaId];
                      return (
                        <Draggable
                          key={ideaId}
                          draggableId={ideaId}
                          index={index}
                          isDragDisabled={!ownList}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <IdeaCard
                                idea={idea}
                                wishList={list}
                                expandedIdeaId={expandedIdeaId}
                                setExpandedIdeaId={setExpandedIdeaId}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {_.isEmpty(list.ideas) &&
                (list.isExtra || !event.options.selfListRequired) &&
                list.author.uid === user.uid && (
                  <DeleteButtonWithConfirmation
                    onDelete={() => deleteWishlist({ wishListId: list.id })}
                    itemName="Wish List"
                  />
                )}
            </Flex>
            {_.size(list.ideas) < event.options.maxIdeasPerList && (
              <AddButtonWithText
                submit={addIdeaFromTitle}
                buttonText="Add Idea"
                disabled={list.id === CREATING_WISHLIST_ID}
              />
            )}
          </Flex>
        </AccordionDetails>
      </Accordion>
    </DragDropContext>
  );
};
