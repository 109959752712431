import {
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { Flex } from "./Flex";
import { Comment } from "../models/functions";
import { useGetUserShortName, useUser } from "../redux/selectors";
import { useCallback, useRef, useState } from "react";
import { useDispatcher, useSelectorWithPrefix } from "../utils/fetchers";
import {
  deleteCommentAction,
  updateCommentAction,
} from "../redux/slices/wishLists";
import { RichText } from "./RichText";
import {
  Check,
  Delete,
  Edit,
  MoreVert,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  deleteText: {
    color: theme.palette.error.main,
  },
}));

type CommentCardProps = {
  comment: Comment;
  wishListId: string;
  ideaId: string;
};

export const CommentCard = ({
  comment,
  wishListId,
  ideaId,
}: CommentCardProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const user = useUser();
  const ref = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(comment.text);
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const updateComment = useDispatcher(updateCommentAction);
  const deleteComment = useDispatcher(deleteCommentAction);
  const getShortName = useGetUserShortName();
  const isCommentAuthor = comment.author.uid === user?.uid;
  const wishLists = useSelectorWithPrefix("wishLists");
  const wishList = wishLists.data[wishListId];
  const showShowGifteeMenuItem =
    wishList?.author.uid !== user?.uid && !wishList.isExtra;
  return (
    <Flex alignItems="center" justifyContent="space-between" gap="8px">
      <Flex alignItems="baseline">
        <Flex>
          <Typography
            variant="button"
            sx={{
              fontWeight: "bold",
              color: isCommentAuthor
                ? theme.palette.mode === "light"
                  ? "blue"
                  : "deepskyblue"
                : "text.primary",
              whiteSpace: "pre",
            }}
          >
            {`${comment.showGiftee ? "*" : ""}${getShortName(
              comment.author.uid
            )}: `}
          </Typography>
        </Flex>
        {isCommentAuthor && isEditing ? (
          <TextField
            value={text}
            onChange={(evt) => setText(evt.target.value)}
            variant="standard"
            fullWidth
            multiline
          />
        ) : (
          <RichText content={text} />
        )}
      </Flex>

      {isCommentAuthor && !isEditing && (
        <>
          <IconButton ref={ref} onClick={() => setIsOpen(!isOpen)}>
            <MoreVert />
          </IconButton>
          <Menu
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorEl={ref.current}
          >
            {showShowGifteeMenuItem && (
              <MenuItem
                onClick={() => {
                  updateComment({
                    wishListId: wishListId,
                    ideaId: ideaId,
                    commentId: comment.id,
                    text,
                    showGiftee: !comment.showGiftee,
                  });
                  handleClose();
                }}
              >
                {comment.showGiftee ? (
                  <>
                    <VisibilityOff />
                    Hide From Giftee
                  </>
                ) : (
                  <>
                    <Visibility />
                    Show To Giftee
                  </>
                )}
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                setIsEditing(true);
                handleClose();
              }}
            >
              <Edit />
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                deleteComment({
                  wishListId: wishListId,
                  ideaId: ideaId,
                  commentId: comment.id,
                });
                handleClose();
              }}
              className={classes.deleteText}
            >
              <Delete />
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
      {isEditing && (
        <IconButton
          onClick={() => {
            updateComment({
              wishListId: wishListId,
              ideaId: ideaId,
              commentId: comment.id,
              text,
              showGiftee: comment.showGiftee,
            });
            setIsEditing(false);
          }}
        >
          <Check color="success" />
        </IconButton>
      )}
    </Flex>
  );
};
