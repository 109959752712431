import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { GetAllExchangeEventsRequest } from "../../functions/src/models";
import {
  GetExchangeEventRequest,
  GetExchangeEventResponse,
  GetAllWishListsRequest,
  GetAllWishListsResponse,
  AddCommentRequest,
  AddCommentResponse,
  AddIdeaRequest,
  AddIdeaResponse,
  CreateWishListRequest,
  CreateWishListResponse,
  DeleteCommentRequest,
  DeleteCommentResponse,
  DeleteWishListRequest,
  DeleteWishListResponse,
  DeleteIdeaRequest,
  DeleteIdeaResponse,
  MarkIdeaRequest,
  MarkIdeaResponse,
  UpdateCommentRequest,
  UpdateCommentResponse,
  UpdateIdeaMetadataRequest,
  UpdateIdeaMetadataResponse,
  UpdateWishListMetadataRequest,
  UpdateWishListMetadataResponse,
  GetAllExchangeEventsResponse,
  CreateExchangeEventRequest,
  CreateExchangeEventResponse,
  UpdateExchangeEventRequest,
  UpdateExchangeEventResponse,
  DeleteExchangeEventRequest,
  DeleteExchangeEventResponse,
  UpdateIdeaOrderRequest,
  UpdateIdeaOrderResponse,
} from "../models/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCM_I2WVybAfyYaJ3Kdq7KpavXQJlm4b1Y",
  authDomain: "thegifter.app",
  projectId: "thegifter-ae6f8",
  storageBucket: "thegifter-ae6f8.appspot.com",
  messagingSenderId: "868651789283",
  appId: "1:868651789283:web:11d93f86b751cb1b7dced6",
  measurementId: "G-8JE44RXH4V",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const firestoreDb = firebase.firestore();
if (window.location.hostname === "localhost") {
  firestoreDb.useEmulator("localhost", 8080);
}

const isDev = process.env.NODE_ENV === "development";

const functions = isDev
  ? getFunctions(app, "http://localhost:5001/")
  : getFunctions(app);

if (isDev) {
  firebase.auth().useEmulator("http://localhost:9099");
}

const getHealth = httpsCallable<undefined, "OK">(
  functions,
  `${isDev ? "thegifter-ae6f8/us-central1/" : ""}health`
);

export const checkHealth = async () => {
  console.log("Check Health Request");
  const response = await getHealth();
  console.log("Check Health Response", response);
  return response.data;
};

const makeFunctionsCall = <Req, Res>(name: string) => {
  const callable = httpsCallable<Req, Res>(
    functions,
    `${isDev ? "thegifter-ae6f8/us-central1/" : ""}${name}`
  );
  const result = async (req: Req) => {
    console.log(`${name} Request`, req);
    const response = await callable(req);
    console.log(`${name} Response`, response);
    return response.data;
  };
  result.displayName = name;
  return result;
};

export const createWishListOnServer = makeFunctionsCall<
  CreateWishListRequest,
  CreateWishListResponse
>("createwishlist");
export const deleteWishListOnServer = makeFunctionsCall<
  DeleteWishListRequest,
  DeleteWishListResponse
>("deletewishlist");
export const updateWishListMetadataOnServer = makeFunctionsCall<
  UpdateWishListMetadataRequest,
  UpdateWishListMetadataResponse
>("updatewishlistmetadata");
export const addIdeaOnServer = makeFunctionsCall<
  AddIdeaRequest,
  AddIdeaResponse
>("addidea");
export const deleteIdeaOnServer = makeFunctionsCall<
  DeleteIdeaRequest,
  DeleteIdeaResponse
>("deleteidea");
export const markIdeaOnServer = makeFunctionsCall<
  MarkIdeaRequest,
  MarkIdeaResponse
>("markidea");
export const updateIdeaMetadataOnServer = makeFunctionsCall<
  UpdateIdeaMetadataRequest,
  UpdateIdeaMetadataResponse
>("updateideametadata");
export const addCommentOnServer = makeFunctionsCall<
  AddCommentRequest,
  AddCommentResponse
>("addcomment");
export const deleteCommentOnServer = makeFunctionsCall<
  DeleteCommentRequest,
  DeleteCommentResponse
>("deletecomment");
export const updateCommentOnServer = makeFunctionsCall<
  UpdateCommentRequest,
  UpdateCommentResponse
>("updatecomment");

export const getExchangeEventFromServer = makeFunctionsCall<
  GetExchangeEventRequest,
  GetExchangeEventResponse
>("getexchangeevent");

export const getAllWishListsFromServer = makeFunctionsCall<
  GetAllWishListsRequest,
  GetAllWishListsResponse
>("getallwishlists");

export const getAllExchangeEvents = makeFunctionsCall<
  GetAllExchangeEventsRequest,
  GetAllExchangeEventsResponse
>("getallexchangeevents");

export const createExchangeEvent = makeFunctionsCall<
  CreateExchangeEventRequest,
  CreateExchangeEventResponse
>("createexchangeevent");

export const updateExchangeEvent = makeFunctionsCall<
  UpdateExchangeEventRequest,
  UpdateExchangeEventResponse
>("updateexchangeevent");

export const deleteExchangeEvent = makeFunctionsCall<
  DeleteExchangeEventRequest,
  DeleteExchangeEventResponse
>("deleteexchangeevent");

export const updateIdeaOrder = makeFunctionsCall<
  UpdateIdeaOrderRequest,
  UpdateIdeaOrderResponse
>("updateideaorder");
