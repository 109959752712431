import { colors, createTheme } from "@mui/material";

const theme = createTheme();

export const CARD_WIDTH = 400;
export const CARD_GAP = 32;
export const CARDS_CONTAINER_PADDING = 32;

export const BACKGROUND_COLOR = {
  light: colors.blue[100],
  dark: colors.blue[900],
};

export const OWN_WISHLIST_BG = {
  light: colors.amber[50],
  dark: colors.amber[900],
};

export const OWN_WISHLIST_TEXT = {
  light: theme.palette.getContrastText(colors.amber[50]),
  dark: theme.palette.getContrastText(colors.amber[900]),
};

export const EXTRA_WISHLIST_BG = {
  light: colors.purple[50],
  dark: colors.purple[900],
};

export const EXTRA_WISHLIST_TEXT = {
  light: theme.palette.getContrastText(colors.purple[50]),
  dark: theme.palette.getContrastText(colors.purple[900]),
};

export const OTHER_WISHLIST_BG = {
  light: theme.palette.primary.light,
  dark: theme.palette.primary.dark,
};

export const OTHER_WISHLIST_TEXT = {
  light: theme.palette.getContrastText(theme.palette.primary.light),
  dark: theme.palette.getContrastText(theme.palette.primary.dark),
};
