import {
  Button,
  IconButton,
  Snackbar,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PropsWithChildren, useEffect, useState } from "react";
import { Flex } from "../components/Flex";
import { SignIn } from "../components/SignIn";
import { useUser } from "../redux/selectors";
import { ArrowBack, Share } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import logoBlack from "../assets/TheGifterTextLogo.png";
import logoWhite from "../assets/TheGifterTextLogoWhite.png";
import { UserAccount } from "../components/modals/UserAccount";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
    background: theme.palette.mode === "light" ? blue[100] : blue[900],
  },
  header: {
    fontWeight: 600,
    padding: "8px 16px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  img: {
    height: 40,
  },
}));

const PageWrapper = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  const classes = useStyles();
  const user = useUser();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [sentVerifyEmail, setSentVerifyEmail] = useState(false);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (sentVerifyEmail) {
      timeout = setTimeout(() => setSentVerifyEmail(false), 10000);
    }
    return () => clearTimeout(timeout);
  }, [sentVerifyEmail]);
  // Get whether we're at the home page or not
  const isHome = window.location.pathname === "/";

  return (
    <div className={classes.root}>
      <Flex className={classes.header}>
        {isHome ? (
          <div />
        ) : (
          <a href="/">
            <IconButton>
              <ArrowBack />
            </IconButton>
          </a>
        )}
        <img
          src={theme.palette.mode === "light" ? logoBlack : logoWhite}
          alt="Gifter Logo"
          className={classes.img}
        />
        <Flex columnGap="8px">
          <UserAccount />
          <IconButton
            onClick={() => {
              setSnackbarOpen(true);
              // Copy the url to the clipboard
              navigator.clipboard.writeText(window.location.href);
            }}
          >
            <Share />
          </IconButton>
        </Flex>
      </Flex>

      {!user ? (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <Typography>Sign In to see your stuff!</Typography>
          <SignIn />
        </Flex>
      ) : !user.emailVerified ? (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          padding="32px"
        >
          <Typography>
            {`Please verify your email ${user.email} to continue using The Gifter app!`}
          </Typography>
          <Button
            onClick={async () => {
              setSentVerifyEmail(true);
              await user.sendEmailVerification({ url: window.location.href });
            }}
            variant="contained"
            disabled={sentVerifyEmail}
          >
            {sentVerifyEmail ? "Sent verification email!" : "Verify Email"}
          </Button>
        </Flex>
      ) : (
        children
      )}
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="Copied link to clipboard!"
        autoHideDuration={2000}
      />
    </div>
  );
};

export default PageWrapper;
