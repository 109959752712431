import firebase from "firebase/compat/app";
import { Logout, PersonOutline } from "@mui/icons-material";
import { useUser } from "../../redux/selectors";
import { useContext, useMemo, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { clearAllAction, userAuthChange } from "../../redux/slices/user";
import { makeStyles, useTheme } from "@mui/styles";
import { Flex } from "../Flex";
import { openInNewAttributes } from "../../constants/base";
import { useUserSettingsValue } from "../../utils/localStorage";
import { ThemeModeContext } from "../../utils/themeMode";

const useStyles = makeStyles((theme: Theme) => ({
  userMenuIcon: {
    height: 40,
    width: 40,
    fontSize: "1.2rem",
    fontWeight: 600,
    border: `2px solid ${theme.palette.grey[500]}`,
  },
}));

export const UserAccount = () => {
  const classes = useStyles();
  const user = useUser();
  const theme = useTheme();
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const { onlyShowMatches, hideMarkedIdeas, setSettingsValue } =
    useUserSettingsValue("onlyShowMatches", "hideMarkedIdeas");
  const { setThemeMode } = useContext(ThemeModeContext);
  const ref = useRef(null);
  const dispatch = useDispatch();

  const userInitials = useMemo(() => {
    const displayInitials =
      user?.displayName?.split(" ").map((name) => name[0]) ?? [];
    return displayInitials.length > 1 ? (
      `${_.first(displayInitials)}${_.last(displayInitials)}`
    ) : displayInitials.length > 0 ? (
      _.first(displayInitials)
    ) : (
      <PersonOutline />
    );
  }, [user]);
  return (
    <>
      {user ? (
        <>
          <IconButton
            ref={ref}
            onClick={() => setUserMenuOpen((prev) => !prev)}
            className={classes.userMenuIcon}
          >
            {userInitials}
          </IconButton>

          <Menu
            open={userMenuOpen}
            onClose={() => setUserMenuOpen(false)}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorEl={ref.current}
          >
            <MenuItem
              onClick={() => {
                setUserMenuOpen(false);
                setAccountDialogOpen(true);
              }}
            >
              <PersonOutline />
              Account
            </MenuItem>
            <MenuItem
              onClick={async () => {
                setUserMenuOpen(false);
                await firebase.auth().signOut();
                dispatch(userAuthChange(null));
                dispatch(clearAllAction.creator());
              }}
            >
              <Logout />
              Logout
            </MenuItem>
          </Menu>
        </>
      ) : null}
      <Dialog
        open={accountDialogOpen}
        onClose={() => setAccountDialogOpen(false)}
      >
        <DialogTitle>Account</DialogTitle>
        <Flex px="16px" pb="16px" flexDirection="column" rowGap="8px">
          <Typography>{`Signed in as ${user?.email}`}</Typography>
          <Flex alignItems="center" columnGap="16px">
            <Typography>Theme</Typography>
            <ToggleButtonGroup
              color="primary"
              value={(theme as Theme).palette.mode}
              exclusive
              onChange={(e, newValue) => newValue && setThemeMode(newValue)}
              aria-label="Platform"
            >
              <ToggleButton value="light">Pastel</ToggleButton>
              <ToggleButton value="dark">Bold</ToggleButton>
            </ToggleButtonGroup>
          </Flex>
          <FormControlLabel
            control={
              <Switch
                checked={onlyShowMatches}
                onChange={(e, checked) =>
                  setSettingsValue("onlyShowMatches", checked)
                }
              />
            }
            label="Only Show Matches"
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideMarkedIdeas}
                onChange={(e, checked) =>
                  setSettingsValue("hideMarkedIdeas", checked)
                }
              />
            }
            label="Hide Marked Ideas"
          />
          <Button
            onClick={async () => {
              setAccountDialogOpen(false);
              await firebase.auth().signOut();
              dispatch(userAuthChange(null));
              dispatch(clearAllAction.creator());
            }}
            variant="outlined"
          >
            <Logout />
            Logout
          </Button>
          <a
            href="https://docs.google.com/document/d/1TFOalEZSp3gnJXt_kmJ8AGagORuS6HANBw4APF-qrhw/edit?usp=sharing"
            {...openInNewAttributes}
          >
            <Typography>Delete Me</Typography>
          </a>
        </Flex>
      </Dialog>
    </>
  );
};
