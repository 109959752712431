import { Box, BoxProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { PropsWithChildren, Ref, forwardRef } from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
    },
  }),
  { name: "flex" }
);

type FlexProps = PropsWithChildren & BoxProps;

export const Flex = forwardRef(
  ({ children, ...props }: FlexProps, ref: Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
      <Box
        {...props}
        className={classNames(classes.root, props.className)}
        ref={ref}
      >
        {children}
      </Box>
    );
  }
);
