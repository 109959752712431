import { combineReducers, StateFromReducersMapObject } from "redux";
import { user } from "./slices/user";
import { exchangeEvent } from "./slices/exchangeEvent";
import { wishLists } from "./slices/wishLists";
import { userShortNames } from "./slices/userShortNames";

const reducerMap = {
  user,
  exchangeEvent,
  wishLists,
  userShortNames,
};

export type State = StateFromReducersMapObject<typeof reducerMap>;

export const rootReducer = combineReducers(reducerMap);

declare module "react-redux" {
  interface ReduxDefaultRootState extends State {}
}
