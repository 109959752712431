import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageWrapper from "./pages/PageWrapper";
import { Provider } from "react-redux";
import { store } from "./redux/configureStore";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ModalContextProvider } from "./components/modals/ModalContext";
import { GiftExchangeEventPage } from "./pages/GiftExchangeEventPage";
import { ExchangeEventListPage } from "./pages/ExchangeEventListPage";
import { LocalStorageProvider } from "./utils/localStorage";
import { CssBaseline } from "@mui/material";
import { ThemeModeProvider } from "./utils/themeMode";

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

function App() {
  return (
    <Provider store={store}>
      <CacheProvider value={muiCache}>
        <ModalContextProvider>
          <LocalStorageProvider>
            <ThemeModeProvider>
              <CssBaseline />
              <div className="App">
                <Router>
                  <PageWrapper>
                    <Switch>
                      <Route path="/gift-exchange-event/:exchangeEvent">
                        <GiftExchangeEventPage />
                      </Route>
                      <Route path="/">
                        <ExchangeEventListPage />
                      </Route>
                    </Switch>
                  </PageWrapper>
                </Router>
              </div>
            </ThemeModeProvider>
          </LocalStorageProvider>
        </ModalContextProvider>
      </CacheProvider>
    </Provider>
  );
}

export default App;
