import { useState, useLayoutEffect, useRef, useMemo } from "react";

export const useVerticalWrapLayout = (props: {
  keys: string[];
  width: number;
  gap?: number;
  gutters?: number;
  sortStackBy?: (a: string) => number;
}) => {
  const {
    keys,
    width: cardWidth,
    gap: cardGap = 0,
    gutters: cardsContainerPadding = 0,
    sortStackBy = () => 0,
  } = props;
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const cardHeightsRef = useRef<Record<string, number>>(
    keys.reduce((acc, cur) => {
      acc[cur] = 1;
      return acc;
    }, {} as Record<string, number>)
  );
  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const stacks = useMemo(() => {
    // sort heights ascending
    const s = keys
      .slice()
      .sort((a, b) => cardHeightsRef.current[a] - cardHeightsRef.current[b]);

    const listKeys = Array.from(
      {
        length: Math.max(
          Math.min(
            Math.floor(
              (windowSize[0] - 2 * cardsContainerPadding + cardGap) /
                (cardWidth + cardGap)
            ),
            s.length
          ),
          1
        ),
      },
      () => ({
        height: 0,
        keys: [] as string[],
      })
    );
    // Place the tallest card in the shortest column
    let i = 0;
    while (s.length) {
      const tallestKey = s.pop()!;
      listKeys[i].keys.push(tallestKey);
      listKeys[i].height += cardHeightsRef.current[tallestKey];
      // get the index of the shortest column
      i = listKeys.reduce(
        (acc, cur, idx, arr) => (cur.height < arr[acc].height ? idx : acc),
        0
      );
    }
    return listKeys.map((lk) =>
      lk.keys.sort((a, b) => sortStackBy(a) - sortStackBy(b))
    );
  }, [
    cardGap,
    cardWidth,
    cardsContainerPadding,
    keys,
    sortStackBy,
    windowSize,
  ]);

  const cardRefs = useMemo(() => {
    return keys.reduce((acc, key) => {
      acc[key] = (instance) => {
        if (instance) {
          const height = instance.getBoundingClientRect().height;
          if (key in cardHeightsRef.current) return;
          cardHeightsRef.current[key] = height;
          setWindowSize([window.innerWidth, window.innerHeight]);
        }
      };
      return acc;
    }, {} as Record<string, React.RefCallback<HTMLDivElement>>);
  }, [keys]);

  return {
    cardRefs,
    stacks,
    windowSize,
  };
};
