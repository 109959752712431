import firebase from "firebase/compat/app";
import { Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Flex } from "../components/Flex";
import { useUser } from "../redux/selectors";
import {
  ExchangeEvent,
  GetExchangeEventRequest,
  GetExchangeEventResponse,
  WishList,
} from "../models/functions";
import { useParams } from "react-router-dom";
import { getExchangeEventAction } from "../redux/slices/exchangeEvent";
import {
  createWishListAction,
  CREATING_WISHLIST_ID,
  getAllWishListsAction /* setWishLists */,
} from "../redux/slices/wishLists";
import { WishListCard } from "../components/WishListCard";
import { FetchedComponent } from "../components/fetchers/FetchedComponent";
import {
  anyIsIdle,
  anyIsSuccess,
  FetchedResource,
  Fetcher,
  FetchingActionResponse,
  useDispatcher,
  useReduxState,
} from "../utils/fetchers";
import _ from "lodash";
import { AddButtonWithText } from "../components/AddButtonWithText";
import { makeStyles } from "@mui/styles";
import {
  Checklist,
  Diversity1,
  Edit,
  Refresh,
  Settings,
} from "@mui/icons-material";
import { ModalContext, ModalType } from "../components/modals/ModalContext";
import { DocTitle } from "../utils/useDocTitleEffect";
import { DrawNamesModal } from "../components/modals/DrawNamesModal";
import { useVerticalWrapLayout } from "../utils/useVeritcalWrapLayout";
import {
  CARDS_CONTAINER_PADDING,
  CARD_GAP,
  CARD_WIDTH,
} from "../constants/style";
import { EditEventModal } from "../components/modals/EditEventModal";
import { MyClaimsModal } from "../components/modals/MyClaimsModal";
import { firestoreDb } from "../api/ChristmasListApi";
import { useUserSettingsValue } from "../utils/localStorage";

const useStyles = makeStyles({
  title: {
    wordBreak: "break-word",
  },
  wishListContainer: {
    width: `min(100vw, ${CARD_WIDTH}px)`,
  },
  textButton: {
    color: "inherit",
  },
  settingsButton: {
    position: "absolute",
    right: 0,
  },
  menuItem: {
    gap: "8px",
  },
});

const SettingsMenu = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { setModal } = useContext(ModalContext);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(!isOpen)}>
        <Settings />
      </IconButton>
      <Menu
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorEl={ref.current}
      >
        <MenuItem
          onClick={() => {
            setModal(ModalType.EditEvent);
            handleClose();
          }}
          className={classes.menuItem}
        >
          <Edit />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModal(ModalType.DrawNames);
            handleClose();
          }}
          className={classes.menuItem}
        >
          <Diversity1 />
          Draw Names
        </MenuItem>
      </Menu>
    </>
  );
};

export const GiftExchangeEventPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useUser();
  const { exchangeEvent: exchangeEventUrlParam } = useParams<{
    exchangeEvent: string;
  }>();
  const { setModal } = useContext(ModalContext);
  const [snapshotVersion, setSnapshotVersion] = useState(0);
  const [renderedVersion, setRenderedVersion] = useState(1);
  const { onlyShowMatches } = useUserSettingsValue("onlyShowMatches");
  const [exchangeEvent, fetchExchangeEvent] = useReduxState(
    `exchangeEvent.data.${exchangeEventUrlParam}` as any,
    getExchangeEventAction
  ) as [
    FetchedResource<ExchangeEvent> | undefined,
    Fetcher<
      FetchingActionResponse<GetExchangeEventResponse>,
      [GetExchangeEventRequest]
    >
  ];
  const [wishLists, fetchAllWishLists] = useReduxState(
    "wishLists",
    getAllWishListsAction
  );
  const createNewWishList = useDispatcher(createWishListAction);

  // Fetch exchange event.
  useEffect(() => {
    if (!user) return;
    if (!exchangeEventUrlParam) return;
    if (anyIsIdle(exchangeEvent)) {
      fetchExchangeEvent({
        exchangeEvent: exchangeEventUrlParam,
      });
    }
  }, [exchangeEvent, exchangeEventUrlParam, fetchExchangeEvent, user]);

  // Fetch Wish Lists.
  useEffect(() => {
    if (!user) return;
    if (!exchangeEventUrlParam) return;
    if (anyIsIdle(wishLists))
      fetchAllWishLists({ exchangeEvent: exchangeEventUrlParam });
  }, [exchangeEventUrlParam, fetchAllWishLists, user, wishLists]);

  useEffect(() => {
    const unsubscribe = (
      firestoreDb.collection(
        "wishList"
      ) as firebase.firestore.CollectionReference<WishList>
    )
      .where("exchangeEvent", "==", exchangeEventUrlParam)
      .onSnapshot(() => {
        setSnapshotVersion((v) => v + 1);
      });
    return () => {
      unsubscribe();
    };
  }, [dispatch, exchangeEventUrlParam]);

  const numExtraLists = useMemo(() => {
    return (
      _.countBy(wishLists.data, (list) =>
        list.isExtra ? "extraLists" : "other"
      ).extraLists ?? 0
    );
  }, [wishLists.data]);

  const gifterIndex =
    exchangeEvent?.data.drawNames.gifters.findIndex(({ emails }) =>
      emails.includes(user?.email || "")
    ) ?? -1;

  const cardKeys = useMemo(
    () =>
      _.keys(
        _.pickBy(wishLists.data, (list) => {
          if (!exchangeEvent) return true;
          if (gifterIndex === -1) return true;
          if (!onlyShowMatches) return true;
          const { gifters, matches } = exchangeEvent?.data.drawNames;
          return gifters[matches[gifterIndex]].emails.includes(
            list.author.email
          );
        })
      ),
    [wishLists.data, onlyShowMatches, exchangeEvent, gifterIndex]
  );
  const sortStackBy = useCallback(
    (key: string) => {
      const list = wishLists.data[key];
      const { gifters = [], matches = [] } =
        exchangeEvent?.data.drawNames ?? {};
      const isAMatch = gifters[matches[gifterIndex]]?.emails.includes(
        list.author.email
      );
      const sortingNumber = +`${
        isAMatch ? 1 : list.isExtra ? 3 : list.author.uid === user?.uid ? 0 : 2
      }.${list.isExtra ? list.createdAt : 1e13 - list.createdAt}`;
      return sortingNumber;
    },
    [exchangeEvent, gifterIndex, user, wishLists.data]
  );

  const { cardRefs, stacks } = useVerticalWrapLayout({
    width: CARD_WIDTH,
    gap: CARD_GAP,
    gutters: CARDS_CONTAINER_PADDING,
    keys: cardKeys,
    sortStackBy,
  });

  const hasOwnList = useMemo(() => {
    return !!_.find(
      wishLists.data,
      (list) =>
        (!list.isExtra && list.author.uid === user?.uid) ||
        list.id === CREATING_WISHLIST_ID
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid, wishLists.data]);

  useEffect(() => {
    if (
      anyIsSuccess(exchangeEvent) &&
      anyIsSuccess(wishLists) &&
      !hasOwnList &&
      exchangeEvent?.data.options.selfListRequired
    ) {
      createNewWishList({
        title: user?.displayName ?? "My List",
        exchangeEvent: exchangeEventUrlParam,
        isExtra: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeEvent?.status, wishLists.status, hasOwnList]);

  const userIsOwner = exchangeEvent?.data.author.uid === user?.uid;

  const reallyHasChanges = renderedVersion < snapshotVersion;

  return (
    <Flex flexDirection="column" p={3} overflow="auto">
      {!!user ? (
        <Flex flexDirection="column" gap="8px">
          <FetchedComponent resource={exchangeEvent}>
            {(data) => (
              <Flex
                flexDirection="column"
                justifyContent="center"
                overflow="hidden"
              >
                <DocTitle title={data.name} />
                <Flex justifyContent="space-between" position="relative">
                  <Flex>
                    <Typography variant="h2" className={classes.title}>
                      {data.name}
                    </Typography>
                  </Flex>
                  <div className={classes.settingsButton}>
                    {userIsOwner && <SettingsMenu />}
                    {reallyHasChanges && (
                      <IconButton
                        onClick={() => {
                          fetchAllWishLists({
                            exchangeEvent: exchangeEventUrlParam,
                          });
                          setRenderedVersion(snapshotVersion);
                        }}
                      >
                        <Refresh />
                      </IconButton>
                    )}
                  </div>
                </Flex>
                <Typography variant="subtitle1">{data.description}</Typography>
                <Typography variant="subtitle1">
                  {moment(data.date).format("dddd, MMMM Do YYYY")}
                </Typography>
                <Button
                  startIcon={<Checklist />}
                  onClick={() => setModal(ModalType.MyClaims)}
                  className={classes.textButton}
                >
                  My Gifts
                </Button>
              </Flex>
            )}
          </FetchedComponent>
          <FetchedComponent resource={wishLists}>
            {(data) => (
              <Flex flexDirection="column">
                <Flex justifyContent="flex-end" gap="8px" flexWrap="wrap">
                  {!hasOwnList &&
                  !exchangeEvent?.data.options.selfListRequired ? (
                    <Button
                      variant="contained"
                      onClick={() =>
                        createNewWishList({
                          exchangeEvent: exchangeEventUrlParam,
                          isExtra: false,
                        })
                      }
                      size="medium"
                      color="success"
                    >
                      Start My List
                    </Button>
                  ) : null}
                  <Flex alignItems="center">
                    {exchangeEvent?.data.options.extraListsAllowed &&
                      (hasOwnList ||
                        !exchangeEvent?.data.options.selfListRequired) &&
                      numExtraLists <=
                        exchangeEvent?.data.options.maxExtraLists && (
                        <AddButtonWithText
                          submit={(text) => {
                            return createNewWishList({
                              title: text,
                              exchangeEvent: exchangeEventUrlParam,
                              isExtra: true,
                            });
                          }}
                          buttonText="Create List For Someone Else"
                          initialText="Extra List"
                          size="medium"
                        />
                      )}
                  </Flex>
                </Flex>
                <Flex
                  gap={`${CARD_GAP}px`}
                  justifyContent="center"
                  py={`32px`}
                  alignItems="flex-start"
                  flexGrow={1}
                >
                  {stacks.map((stack, i) => (
                    <Flex
                      gap={`${CARD_GAP}px`}
                      flexDirection="column"
                      key={`${stack.join(",")} - ${i}`}
                    >
                      {stack.map((listKey, j) => {
                        const list = wishLists.data[listKey];
                        return (
                          <div
                            key={`${listKey} - ${j}`}
                            ref={cardRefs[listKey]}
                            className={classes.wishListContainer}
                          >
                            {exchangeEvent && (
                              <WishListCard
                                list={list}
                                user={user}
                                event={exchangeEvent.data}
                              />
                            )}
                          </div>
                        );
                      })}
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            )}
          </FetchedComponent>
        </Flex>
      ) : null}
      <DrawNamesModal />
      <EditEventModal />
      <MyClaimsModal />
    </Flex>
  );
};
