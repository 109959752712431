import { Dialog, Theme } from "@mui/material";
import { useContext } from "react";
import { ModalContext, ModalType } from "./ModalContext";
import { ExchangeEventCard } from "../ExchangeEventCard";
import { useParams } from "react-router-dom";
import { FetchedResource, useSelectorWithPrefix } from "../../utils/fetchers";
import { ExchangeEvent } from "../../models/functions";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    "& > .MuiDialog-container > .MuiDialog-paper": {
      [theme.breakpoints.down(960)]: {
        margin: 0,
      },
    },
  },
}));

export const EditEventModal = () => {
  const classes = useStyles();
  const { modal, setModal } = useContext(ModalContext);
  const { exchangeEvent: exchangeEventUrlParam } = useParams<{
    exchangeEvent: string;
  }>();
  const exchangeEvent = useSelectorWithPrefix(
    `exchangeEvent.data.${exchangeEventUrlParam}` as any
  ) as FetchedResource<ExchangeEvent>;
  return (
    <Dialog
      className={classes.modal}
      open={modal === ModalType.EditEvent}
      onClose={() => setModal(null)}
    >
      {exchangeEvent && (
        <ExchangeEventCard
          exchangeEvent={exchangeEvent.data}
          userOptions={exchangeEvent.data?.users}
          initialEditMode={true}
          onCancel={() => setModal(null)}
          onSave={() => setModal(null)}
          inModal
        />
      )}
    </Dialog>
  );
};
