import { Theme, Typography, TypographyProps } from "@mui/material";
import * as Prism from "prismjs";
import { Fragment } from "react";
import { openInNewAttributes } from "../constants/base";
import { makeStyles } from "@mui/styles";

const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const grammar: Prism.Grammar = {
  url: URL_REGEX,
};

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.mode === "dark" ? "deepskyblue" : "blue",
  },
}));

export type RichTextProps = TypographyProps & {
  content: string;
};
export const RichText = ({ content, ...typProps }: RichTextProps) => {
  const classes = useStyles();
  const tokens = Prism.tokenize(content, grammar);
  return (
    <Typography {...typProps}>
      {tokens.map((token, i) => {
        const href =
          typeof token === "string"
            ? ""
            : (token.content as string).match(
                /amazon\.com|amzn\.to|https:\/\/a.co\//
              )
            ? `${token.content}?tag=thegifterapp-20`
            : (token.content as string);
        return typeof token === "string" ? (
          <Fragment key={i}>{token}</Fragment>
        ) : (
          <a
            key={i}
            href={href}
            {...openInNewAttributes}
            className={classes.link}
          >
            {token.content as string}
          </a>
        );
      })}
    </Typography>
  );
};
